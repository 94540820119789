import React, { useContext } from "react";
import { useState } from "react";
import { MenuContext } from "react-flexible-sliding-menu";
import { AiOutlineMenu } from "react-icons/ai";
// pop up
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { API_URL } from "../constants";
import useFetch from "../utils/useFetch";
import Franchise from "./popUps/franchise";
// for reservation form
import Reservation from "./popUps/reservation";

const Nav = () => {
  // for nav background

  const { data: franchises } = useFetch(`${API_URL}franchises`);

  const [navbar, setNavbar] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  // to open full page menu
  const { toggleMenu } = useContext(MenuContext);

  const ReserationPopUp = withReactContent(Swal);

  const showReservationPopup = () => {
    ReserationPopUp.fire({
      title: "Reserve a Table",
      html: <Reservation />,
      showConfirmButton: false,
      showCloseButton: true,
    });
  };

  const changeBackground = () => {
    if (window.scrollY >= 80) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  window.addEventListener("scroll", changeBackground);

  // franchise pop up
  const FranchisePopUp = withReactContent(Swal);
  const showFranchisePopup = () => {
    FranchisePopUp.fire({
      // title: "Franchising Initial Inquiry Form",
      title: "Franchising Inquiry",
      html: <Franchise />,
      showConfirmButton: false,
      showCloseButton: true,
    });
  };

  return (
    <section
      onMouseEnter={() => window.scrollY <= 80 && setNavbar(true)}
      onMouseLeave={() =>
        window.scrollY <= 80
          ? setNavbar(false) && setDropdown(false)
          : setDropdown(false)
      }
      className={`nav ${navbar ? "active" : ""} h-fit`}
      // className={navbar ? "nav active" : "nav"}
    >
      <div className="nav-wrapper xl:flex hidden text-center lg:justify-center text-base w-fit xl:w-full my-5 imac:my-20 relative">
        <a href="/">Home</a>

        <div className="dropdown">
          <button
            // onClick={() => setDropdown(!dropdown)}
            onMouseEnter={() => setDropdown(true)}
          >
            Outlets
          </button>
        </div>

        <a href="/openingSoon">Achievement and community footprints</a>
        <a
          onClick={() => {
            showFranchisePopup();
          }}
        >
          Franchise
        </a>

        <a href="/careers">Careers</a>

        <button
          className="2xl:absolute imac:text-4xl imac:h-[3.5vh] imac:right-[3vw] right-5 rounded-md bg-black px-11 py-2 font-semibold text-white transition-all duration-500 ease-in-out hover:bg-[#ebf3e8] hover:text-black hover:outline hover:outline-2 hover:outline-black"
          onClick={() => {
            showReservationPopup();
          }}
        >
          Reserve Now
        </button>
        {/* dropdown menu for outlets */}
        <div
          onMouseLeave={() => setDropdown(false)}
          className={`dropdown-content ${
            dropdown ? "visible" : ""
          } absolute min-w-[50%] -z-10 bg-[#f7f7f7] grid grid-cols-5 gap-8 p-5 imac:gap-20 imac:py-20 drop-shadow-md border border-light-green`}
        >
          {franchises &&
            franchises.map((franchise, index) => (
              <>
                {franchise.coming_soon ? (
                  ""
                ) : (
                  <div
                    className={`pb-4 break-inside-avoid mb-4 border-2 border-nav-green imac:leading-[170px] max-w-[35vw]`}
                  >
                    <a
                      href={
                        franchise.coming_soon
                          ? "/openingsoon"
                          : franchise.id === "peros"
                          ? `/${franchise.id}/p_thamel`
                          : franchise.id === "jade"
                          ? "https://www.jadecity.com.np"
                          : `/${franchise.id}/`
                      }
                      target={franchise.id === "jade" ? "_blank" : ``}
                      rel="noreferrer"
                      key={index}
                      className="mt-4 font-bold"
                    >
                      <img
                        className="h-8 mx-auto"
                        src={franchise.logo}
                        alt={franchise.name}
                      />
                    </a>
                    <div className="">
                      {franchise.outlets.map((outlet, index) => (
                        <a
                          href={
                            outlet.coming_soon
                              ? "/openingsoon"
                              : franchise.id === "jade"
                              ? "https://www.jadecity.com.np"
                              : `/${franchise.id}/${outlet.id}`
                          }
                          target={franchise.id === "jade" ? "_blank" : ``}
                          rel="noreferrer"
                          key={index}
                        >
                          {outlet.name}
                        </a>
                      ))}
                    </div>
                  </div>
                )}
              </>
            ))}
        </div>
      </div>

      {/* SLIDE MENU open full page menu */}
      <span className="toggleMenu xl:hidden" onClick={toggleMenu}>
        <AiOutlineMenu />
      </span>
    </section>
  );
};

export default Nav;
